$self: ".App";
$header-gap: 25px;

html,
body {
  height: 100%;
}

.js-copy {
  cursor: pointer;

  * {
    pointer-events: none;
  }
}

body {
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;

  &.is-cut {
    overflow: hidden;
  }

  &.is-staging::before {
    content: "";
    position: fixed;
    z-index: 2147483647;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    box-shadow: inset 0 0 15px rgb(0 255 0 / 90%);
    pointer-events: none;
  }
}

body.App {
  background: #f6f6f6;
  font-family: $gotham-book;
  font-weight: normal;

  &.is-cut,
  &.is-zen-cut {
    overflow: hidden;
    height: auto;

    .editor-panels {
      z-index: 900;
    }

    .Locale-entries-sidebar {
      z-index: 700;
    }
  }

  ul {
    &.is-squared {
      list-style-type: square;
    }
  }

  p {
    font-family: $gotham-book;
    font-weight: normal;

    b {
      font-family: $gotham-bold;
    }
  }

  strong,
  .strong {
    font-family: $gotham-bold;
    font-weight: normal;
  }

  .text-wrap {
    &.is-break {
      word-wrap: break-word;
      max-width: 300px;
    }

    &.is-no-wrap {
      white-space: nowrap;
    }
  }

  .text-normal {
    font-family: $gotham-book;
    font-weight: normal;
  }

  .text-center {
    text-align: center;
  }

  .inline-link {
    color: #0070d9;
    font-family: $gotham-book;
    word-break: break-word;

    &:hover {
      color: #ff4f00;
      text-decoration: none;
    }

    &:focus {
      text-decoration: none;
      outline: none;
    }
  }

  .inline-block {
    display: inline-block;
    vertical-align: middle;

    b {
      font-family: $gotham-medium;
    }
  }

  table {
    &.is-fixed {
      table-layout: fixed;
    }
  }

  div,
  span {
    &.is-link {
      cursor: pointer;
      color: $blue-secondary;

      &:hover,
      &:focus {
        color: #005580;
        text-decoration: underline;
      }

      &:hover,
      &:active {
        outline: 0;
      }
    }
  }

  .header {
    border-bottom: 0;
    margin-bottom: $header-gap;
  }

  .muted {
    color: #aaa;
  }

  #center {
    margin-top: 0;
  }
}

.App-holder {
  $footer-height: 160px;

  margin-bottom: -$footer-height;
  min-height: 100vh;

  &::after {
    content: "";
    display: block;
    height: $footer-height;
  }
}

#{$self} {
  &-title {
    color: #333;
    font-family: $gotham-bold;
    font-size: 28px;
    line-height: 1.2;
    display: flex;
    align-items: center;

    &.is-hero {
      font-size: 46px;
      text-align: center;
      text-transform: uppercase;

      @include mq-max-width($width-3of7) {
        font-size: 28px;
      }
    }
  }

  &-subtitle {
    color: #333;
    font-family: $gotham-bold;
    font-size: 19px;
    line-height: 1.2;
    margin-bottom: 12px;
  }

  &-wrapper {
    box-shadow: 0 0 10px rgba(#000, 0.1);
    position: relative;
    background: #fafafa;
  }

  &-blinking {
    animation: blinker 1s linear infinite;
  }

  @include keyframes(blinker) {
    from {
      opacity: 1;
    }

    50% {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  &-highlight {
    background-color: #feffa7;
  }

  &-caret-arrow {
    background: url("~chosen/chosen-sprite.png") 0 -5px no-repeat;
    height: 7px;
    margin-left: 8px;
    width: 11px;
    display: inline-block;
    opacity: 0.25s;
  }

  &-coupon {
    display: inline-block;
    position: relative;
    vertical-align: middle;

    &-code {
      border: 1px solid #aaa;
      border-radius: 50px;
      color: #333;
      font-family: $gotham-bold;
      font-size: 11px;
      padding: 0 10px;
      line-height: 1.5;
    }

    &-tip {
      display: inline-block;
      vertical-align: middle;
    }
  }

  &-link-icon {
    border: 1px solid #ddd;
    border-radius: 50%;
    display: inline-block;
    height: 18px;
    line-height: 14px;
    padding: 0;
    vertical-align: middle;
    width: 18px;

    &:hover,
    &:focus {
      border-color: #999;
    }

    span {
      margin: 2px;
    }
  }

  &-trend {
    display: inline-block;
    font-size: 14px;
    font-style: normal;
    position: relative;
    vertical-align: middle;

    &.is-small {
      font-size: 10px;
      line-height: 11px;
    }

    &.is-down {
      color: #d61822;

      &::before {
        content: "⬇";
      }
    }

    &.is-up {
      color: #429f53;

      &::before {
        content: "⬆";
      }
    }
  }

  &-chart {
    height: 260px;

    .highcharts-legend-item {
      display: none;
    }

    &-legend {
      &-round-item {
        font-size: 14px;
        font-family: $gotham-medium;

        span {
          border-radius: 50%;
        }

        small {
          font-size: 100%;
          color: #999;
        }
      }

      &-item {
        border: 1px solid rgba(#000, 0.1);
        border-radius: 4px;
        color: $base;
        font-family: $gotham-book;
        font-size: 11px;
        font-weight: normal;
        margin-right: 15px;
        padding: 4px 10px 6px;
        transition: 0.3s;

        &:hover {
          border-color: $firm;
          color: $firm;
        }

        &.is-hidden {
          display: none;
        }

        &.is-inactive {
          color: #ddd;

          &:hover {
            border-color: #ccc;
            color: #bbb;
          }

          span {
            color: #ddd !important;
            transition: 0.3s;
          }
        }
      }
    }
  }

  &-code {
    color: #93819c;
    font-family: $monaco;
    font-size: 12px;
  }

  // Layout grid
  // -----------
  &-layout {
    display: flex;
    flex-direction: column;
    margin: 0 auto $container-padding;
    max-width: $container-width + $container-padding * 2;
    width: calc(100% - 40px);

    &-head {
      margin: 35px 0;
    }

    &-headline {
      @extend #{$self}-layout;

      max-width: $container-width;

      #{$self}-title {
        margin-bottom: 0;
      }
    }

    &.is-content-sidebar {
      flex-direction: row;
      justify-content: space-between;

      #{$self} {
        &-content {
          width: 78%;
        }

        &-sidebar {
          width: 22%;
        }
      }
    }

    &-container {
      background-color: #fff;
      box-shadow: 0 0 10px rgba(#000, 0.1);

      &.is-rounded {
        border-radius: 12px;
      }
    }

    &-content {
      padding: $container-padding;

      &-table {
        margin: 0;

        th,
        td {
          &:first-child {
            padding-left: $container-padding;
          }

          &:last-child {
            padding-right: $container-padding;
          }
        }

        &-holder {
          margin: 0 #{-$container-padding};
        }
      }
    }
  }

  &-navbar {
    background: #f1f1f1;
    margin-bottom: $header-gap;
    margin-top: -$header-gap;
    position: sticky;
    border-bottom: 1px solid #d3d3d3;
    top: 0;
    z-index: 3;

    &-grid {
      display: flex;
      justify-content: space-between;
    }

    &-links {
      color: #666;
      display: inline-block;
      font: 12px $gotham-bold;
      padding: 8px 0;
      cursor: pointer;

      span:first-child {
        padding-left: 4px;
      }

      &:hover {
        text-decoration: none;

        .App-navbar-link {
          color: $firm;
        }

        .sign-svg-stroke {
          stroke: $firm;
        }
      }
    }

    .sign-svg {
      margin-right: 2px;
    }
  }

  // Other elements and modules overrides
  // ------------------------------------
  [data-toggle="tooltip"] {
    &:hover {
      cursor: pointer;
      text-decoration: none;
    }

    &.is-without-pointer {
      &:hover {
        cursor: help;
      }
    }
  }

  a[data-toggle="tooltip"] {
    &:not([href]):hover {
      cursor: help;
    }
  }
}

body.App .container,
.App-container,
.App-container-inner {
  width: $container-width;
  margin: 0 auto;
  position: relative;

  @include mq-max-width($width-3of7) {
    padding: 0;
  }

  &.is-static {
    position: static;
  }

  &.is-large {
    width: 1335px;
  }

  &.is-3of4 {
    width: $width-3of4;

    @include mq-max-width($width-3of4) {
      width: 100%;
    }
  }

  &.is-3of5 {
    width: $width-3of5;

    @include mq-max-width($width-3of5) {
      width: 100%;
    }
  }

  &.is-3of7 {
    width: $width-3of7;

    @include mq-max-width($width-3of7) {
      width: 100%;
    }
  }

  &.is-wide {
    padding-left: 0;
    padding-right: 0;
    width: auto;
  }
}

@media all and (width <= 1335px) {
  .App-container,
  .App-container-inner {
    &.is-large {
      width: 100%;
    }
  }
}

@media all and (width <= 1320px) {
  .App-container,
  .App-container-inner {
    box-sizing: border-box;
    width: 100%;
  }

  .App-container-inner {
    padding-left: 25px;
    padding-right: 25px;
  }

  body.App {
    .container {
      width: 100%;
      box-sizing: border-box;
      padding-left: 25px;
      padding-right: 25px;
    }

    #breadcrumbs {
      box-sizing: border-box;
      padding-left: 25px;
      padding-right: 25px;
    }
  }
}

@media all and (resolution >= 2dppx), all and (resolution >= 144dpi) {
  .App {
    &-caret-arrow {
      background-image: url("~chosen/chosen-sprite@2x.png");
      background-size: 53px 80px;
      background-position: 0 -6px;
    }
  }
}

@include print {
  .App-container-inner {
    padding-left: 0;
    padding-right: 0;
  }
}

.editor-changelog-button {
  background-color: #05b405;
  bottom: 6px;
  height: 8px;
  position: absolute;
  overflow: hidden;
  top: 50%;
  margin-top: -4px;
  right: 15px;
  text-decoration: none;
  text-transform: uppercase;
  width: 50px;
  border-radius: 4px;

  @include vertical-gradient(#81d093, #50b064);

  i {
    background-color: #fff;
    content: "";
    height: 100%;
    width: 1px;
    z-index: 2;
    float: left;
    margin-left: 1px;
    opacity: 0.15;

    &:first-child {
      margin-left: 0;
    }
  }

  > span {
    @include vertical-gradient(#ff7e7e, #f12424);

    display: block;
    height: 8px;
  }
}

.Users-table-wrap {
  .foldable-content {
    box-shadow: none;
    padding: 0;
  }

  .foldable-title > a {
    font-size: 16px;
  }
}

.no-wrap {
  white-space: nowrap;
}

.lhn {
  line-height: 1;
}

.scroll-table-wrapper {
  display: block;
  overflow-x: auto;
  white-space: nowrap;
}

.arrow-back {
  display: flex;
  align-items: center;
  font-family: $gotham-medium;
  font-size: 14px;
  transition: all 0.3s ease 0s;
  color: $link-color;

  svg {
    margin-right: 4px;
  }

  &:hover,
  &:active,
  &:focus {
    text-decoration: none;

    svg {
      path {
        fill: $firm;
      }
    }
  }
}

.modal-btn-segments {
  margin-left: auto;
}

.badge {
  display: inline-block;
  padding: 2px 8px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: bold;
  line-height: 1;
  color: white;
  vertical-align: middle;
}

.badge.is-blue {
  font-size: 10px;
  background-color: #0070d9;
  font-weight: bold;
}

.badge.is-orange {
  font-size: 10px;
  background-color: #fc501e;
}

.card {
  padding: 8px;
  width: 23%;
  margin-bottom: 16px;
}

.segment-title {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 12px;
}

.radio-group {
  list-style: none;
  padding: 2px;
  margin: 0;
  background-color: #fafafa;
  border-radius: 4px;
}

.radio-group li {
  cursor: pointer;
  min-height: 32px;
  padding-left: 8px;
  display: flex;
  align-items: center;
  border: 1px solid transparent;
  position: relative;
  border-radius: 4px;
  transition:
    background-color 0.2s ease,
    border-color 0.2s ease;
}

.radio-group li.is-selected {
  background-color: #fff5d0;
  border: 2px solid #0070d9;
}

.base-radio-label {
  font-size: 12px;
  font-weight: normal;
  line-height: 1.4;
  position: relative;
  padding-left: 24px;
  display: flex;
  align-items: center;
  color: #000;
}

.radio-group li.is-selected .base-radio-label {
  font-weight: 800;
}

.base-radio-label::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 16px;
  height: 16px;
  border: 1px solid #ccc;
  background-color: #fff;
  border-radius: 50%;
  box-sizing: border-box;
}

.radio-group li.is-selected .base-radio-label::before {
  background-color: #fff;
  border-color: #0070d9;
}

.radio-group li.is-selected .base-radio-label::after {
  content: "";
  position: absolute;
  left: 4px;
  top: 50%;
  transform: translateY(-50%);
  width: 8px;
  height: 8px;
  background-color: #0070d9;
  border-radius: 50%;
}

.selected-segment-badge {
  display: inline-flex;
  flex-direction: column;
  margin-right: 8px;
  margin-top: 8px;
  font-size: 12px;
}

.selected-segment-badge-title {
  font-size: 10px;
  opacity: 0.8;
  margin-bottom: 2px;
}

.selected-segment-badge-content {
  width: fit-content;
  padding: 2px 9px;
  background-color: #0070d9;
  border-radius: 16px;
  color: white;
  display: flex;
  align-items: center;
  font-weight: bold;
}

.selected-segment-badge-remove {
  color: white;
  font-weight: bold;
  margin: 0 0 2px 8px;
  cursor: pointer;
  font-size: 14px;
}

.selected-segment-badge-remove:hover,
.selected-segment-badge-remove:focus,
.selected-segment-badge-remove:active {
  color: white;
  text-decoration: none;
  opacity: 0.9;
}

.compare-table {
  border: 1px solid #d0d0d0;

  thead tr {
    background-color: #f9f9f9;
  }

  th,
  td {
    padding: 22px 16px;

    &:first-child {
      width: 240px;
    }

    &:not(last-child) {
      border-right: 1px solid #d0d0d0;
    }
  }

  th,
  td:first-child {
    color: #333;
    font-family: $gotham-bold;
    font-size: 16px;
    line-height: 1.2;
  }

  th {
    font-family: $gotham-book;
    font-size: 14px;
  }

  tbody tr {
    &:nth-child(even) td {
      background-color: rgb(44 113 210 / 5%);
    }
  }

  td {
    font-size: 16px;
    font-family: $gotham-book;
    line-height: 1.2;
    font-weight: 400;
  }
}

.staff-badge {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  background-color: rgb(255 222 0);
  width: 32px;
  height: 32px;
  margin: 0 8px 0 0;

  div {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  svg {
    margin-right: 0;
  }
}

.empty-view-header {
  text-align: center;
  font-size: 18px;
  color: #909090;
}

.success-td {
  background-color: #549d421a !important;
  color: #549d42;
  font-weight: bold;
  font-family: $gotham-bold !important;
}

.danger-td {
  background-color: #f3ae3f29 !important;
  color: #f3ae3f;
  font-weight: bold;
  font-family: $gotham-bold !important;
}

.sticky-table-wrapper {
  margin-bottom: 20px;
  width: 100%;
  overflow: auto;
  max-height: calc(100vh - 160px);

  table {
    margin-bottom: 0 !important;
    border-top: none;

    thead {
      position: sticky;
      top: 0;
      z-index: 3;

      th {
        border-top: 1px solid #ddd !important;
        border-bottom: 1px solid #ddd;
        background-color: #fff;
      }
    }

    tbody tr:first-child td {
      border-top: none;
    }
  }
}

.sortable thead th:not(.no-sort) {
  cursor: pointer;
  position: relative;

  &::after {
    font-size: 1.2em;
    color: transparent;
    position: absolute;
    right: 12px;
    content: "";
    width: 6px;
    height: 6px;
    border-top: 2px solid;
    border-right: 2px solid;
    border-color: transparent;
    top: calc(50% - 3px);
    transform: rotate(45deg);
    transition: border-color 0.2s ease-in-out;
  }

  &:hover {
    &::after {
      border-color: #919191;
    }
  }

  &[aria-sort="descending"]::after {
    transform: rotate(135deg);
  }

  &[aria-sort="ascending"]::after {
    transform: rotate(-45deg);
  }

  &[aria-sort="descending"]::after,
  &[aria-sort="ascending"]::after {
    border-color: #919191;
  }
}
