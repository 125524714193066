@import "stylesheets/themes/shared/utils";
@import "stylesheets/application_v2/fonts_config";
@import "stylesheets/application_v2/config";
@import "monaco-editor/min/vs/editor/editor.main.css";

$fluidGridColumnWidth: 6.383% !default;
$fluidGridGutterWidth: 2.128% !default;

@mixin main {
  // Add application styles here

  /* application wide constants */
  $cb-dark-blue: #426e8e;
  $cb-orange: #f26522;
  $cb-button-bg-blue: #3792d3;
  $cb-light-grey: #888;

  body {
    font-family: Arial, sans-serif;
    font-size: 13px;
    line-height: 1.5;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    font-weight: normal;
  }

  h1 {
    font-size: 36px;
    color: $cb-orange;
    margin-bottom: 0.5em;
  }

  h2 {
    font-size: 24px;
    color: $cb-orange;
  }

  h3 {
    //verify to make sure nothing is broken.
    margin: 0 0 5px;
    padding: 0;
  }

  .title-description {
    font-size: 14px;
    display: inline-block;
    vertical-align: middle;
    color: #999;
  }

  pre {
    white-space: pre;
    white-space: pre-wrap; /* css-3 */
    word-wrap: break-word; /* Internet Explorer 5.5+ */
    margin: 4px 4px 4px 0;
    padding: 10px 13px;
    border: none;
    background-color: #efecf0;
    color: #6e517d;
    font-size: 12px;
  }

  code {
    white-space: normal;
  }

  table {
    margin-bottom: 1.4em;
    width: 100%;

    .header {
      td,
      th {
        text-align: center !important;
        vertical-align: middle !important;
      }
    }

    .result {
      td,
      th {
        font-weight: bold;
        border-top-width: 2px;
        background-color: #f9f9f9;
      }
    }
  }

  .table-fixed {
    word-break: break-word;
  }

  .table-scroll-wrapper {
    overflow-x: scroll;
  }

  th {
    font-weight: bold;
  }

  thead th {
    background: transparent;
  }

  th,
  td,
  caption {
    padding: 4px 10px 4px 5px;
  }

  .rdp-cell {
    padding: 0;
  }

  table.striped tr:nth-child(even) td,
  table tr.even td {
    background: transparent;
  }

  ul.pagination {
    margin-left: 0;
    padding-left: 0;
  }

  a.btn.btn-primary,
  a.btn.btn-danger {
    color: white !important;
  }

  a.btn-mini.btn-block + a.btn-mini.btn-block {
    margin-top: 2px;
  }

  input {
    width: auto;
    height: auto;

    &[type="file"] {
      height: auto;
      line-height: 1;
    }
  }

  input[type="text"] {
    height: auto;
    padding: 5px 6px;
  }

  input.with-hint {
    margin-bottom: 0;
  }

  .btn-block {
    padding: 1px 0;
  }

  .btn-mini {
    padding: 1px 6px;
  }

  .btn-wrapped {
    white-space: nowrap;
  }

  // Note: to avoid blinking we need to hide
  // select box until Chosen triggers custom select
  select {
    &.js-select,
    &.js-tag-select {
      display: none;
    }

    &.js-select:invalid {
      height: 0;
      opacity: 0;
      position: absolute;
      display: block !important;
      margin-top: 15px;
    }
  }

  select.site {
    border: 1px solid #bbb;
    font-size: 11px;
    height: 26px;
    margin: 5px 0;
    padding: 2px;
    width: auto;

    &:focus {
      border-color: #666;
    }
  }

  .modal-body {
    overflow-x: hidden;
  }

  a {
    color: #0070d9;
    text-decoration: none;
    transition: 0.25s;

    &:hover {
      color: #ff4f00;
    }

    &.muted {
      color: scale-color($cb-light-grey, $lightness: 20%);
    }

    &[data-toggle="tooltip"]:not([href]) {
      color: inherit;
      cursor: help;

      &:hover {
        text-decoration: none;
      }

      span {
        border-bottom: 1px dashed rgba(#000, 0.25);
      }
    }

    &[data-toggle="tooltip"] {
      .sign {
        opacity: 0;
      }

      &:hover {
        text-decoration: none;
      }
    }
  }

  .datetime-label {
    color: #999;
    display: inline-block;
    vertical-align: middle;
  }

  input.button,
  a.button {
    border-radius: 4px;
    background: $cb-dark-blue;
    border: medium none;
    color: #eee;
    cursor: pointer;
    font:
      138.5% "Helvetica Neue",
      helvetica,
      sans-serif;
    margin: 0;
    padding: 6px 25px;

    &:hover {
      color: #fff;
      background: $cb-button-bg-blue;
    }
  }

  input.mini,
  a.mini {
    padding: 4px 6px;
    font-size: 15px;
    color: #fff;
    margin-top: 5px;
  }

  a.button:hover {
    text-decoration: none;
  }

  .clearboth {
    clear: both;
  }

  .clearfix {
    @include clearfix;
  }

  .word-break {
    word-break: break-all;
  }

  .nobreak {
    white-space: nowrap;
  }

  .red {
    color: #833;
  }

  .grey {
    color: $cb-light-grey;
  }

  .green {
    color: green;
  }

  a.admin {
    color: #a66;
  }

  .b {
    font-weight: bold;
  }

  .example {
    color: #999;
    font-style: italic;
  }

  .disabled {
    color: #9c9c9c;
  }

  .tooltip-old {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
    display: none;
    background-color: white;
    border: 1px solid;
    padding: 5px;
    opacity: 1;
  }

  label {
    cursor: default;

    &.is-inline {
      display: inline-block;
      margin: 0;
      vertical-align: middle;
    }

    &.is-inline-top {
      display: inline-block;
      vertical-align: top;
    }
  }

  input.checkbox {
    display: inline-block;
    margin: 0 2px 0 0;
  }

  .form-holder {
    background-color: #fff;
    border: 1px solid #e5e5e5;
    border-radius: 6px;
    box-shadow: 0 1px 2px rgba(#000, 0.05);
    padding: 25px;
  }

  .textfield-container {
    display: inline-block;
    position: relative;
    width: 270px;
  }

  .tooltip-right {
    position: absolute;
    right: -35px;
    top: 4px;

    .tooltip {
      width: 400px;
    }
  }

  //track down and remove original container
  .container {
    margin: 0 auto;
    width: 1020px;

    @include clearfix;

    &.is-wide {
      padding-left: 20px;
      padding-right: 20px;
      width: auto;
    }
  }

  .fade {
    opacity: 0;
    transition: opacity 0.15s linear;

    &.in {
      opacity: 1;
    }
  }

  .collapse {
    display: none;

    &.in {
      display: block;
      overflow: visible;
    }
  }

  tr.collapse.in {
    display: table-row;
  }

  tbody.collapse.in {
    display: table-row-group;
  }

  .collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    transition-property: height, visibility;
    transition-duration: 0.35s;
    transition-timing-function: ease;
  }

  .foldable {
    &-title {
      padding: 5px 0;

      > a {
        display: flex;

        &,
        &:focus {
          font-family: $gotham-medium;
          font-size: 14px;
          text-decoration: none !important;
          transition: 0.25s;
        }

        &:hover {
          color: $link-color;
        }

        &::after {
          background: url("~application_v2/campaignfilter/dropdown.svg")
            no-repeat 2px 3px;
          border: 2px solid rgb(0 112 217 / 20%);
          border-radius: 50%;
          content: "";
          display: inline-block;
          height: 20px;
          line-height: 20px;
          margin: -2px 0 0 8px;
          transform: rotate(180deg);
          transition: 0.2s;
          vertical-align: middle;
          width: 20px;
        }

        &[aria-expanded="true"]::after {
          transform: rotate(0);
        }
      }
    }

    &-title-new {
      padding: 5px 0;
      border-bottom: 1px solid #ccc;
      margin-bottom: 15px;

      > a {
        color: #0070d9;

        &,
        &::after {
          margin-left: auto;
        }
      }
    }

    &-content {
      background: #fff;
      border-radius: 10px;
      margin-top: 5px;
      padding: 25px;
      transition: box-shadow 0.25s 0.25s; // Has flickering bugs otherwise

      [aria-expanded="true"] &:not(.inner) {
        box-shadow: 0 2px 5px rgba(#000, 0.1);
      }

      &-gap {
        margin-bottom: 20px;
      }
    }

    &-content-new {
      padding: 0;
      margin-top: 0;

      [aria-expanded="true"] &:not(.inner) {
        box-shadow: none;
      }
    }
  }

  .criteria-body {
    background-color: #f7f7f7;
    color: navy;
    font-family: Monaco, monospace;
    font-size: 12px;
    margin: 5px 0 25px;
    padding: 10px;
    white-space: pre-wrap;
    word-wrap: break-word;
  }

  .flash {
    font-size: 14px;
    margin: 0;
    border: 0;
    padding: 6px;
    text-align: center;

    a {
      color: $cb-dark-blue;
      text-decoration: underline;
    }
  }

  .success {
    border-bottom: 1px solid #90b04c;
    background: #cdeb8e;
  }

  .notice {
    border-bottom: 1px solid #c7bb4e;
    background: #efe3a0;
  }

  .fieldWithErrors {
    display: inline;
  }

  #error_explanation {
    border: 1px solid #ae5b65;
    background-color: #fff0f0;
    padding: 10px;

    h2 {
      color: #ae5b65;
      font-size: 14px;
    }
  }

  #center {
    margin-top: 6px;
  }

  .centerBox {
    margin: 0 auto;
  }

  .subtext {
    color: #c4d1d3;
  }

  th.sortDesc {
    a {
      padding-left: 13px;
      background: url("~icons/sort-desc.png") no-repeat 1px 2px;
    }
  }

  th.sortAsc {
    a {
      padding-left: 13px;
      background: url("~icons/sort-asc.png") no-repeat 1px 1px;
    }
  }

  /* footer */
  #footer {
    text-align: left;
    font-size: 12px;
    margin-top: 15px;
    margin-bottom: 20px;
    padding: 0 0 25px;
    clear: both;
    color: #444;

    .copy {
      padding-top: 10px;
      float: left;
    }

    .links {
      float: right;
      margin-right: -16px;
      padding-top: 9px;
    }

    a,
    a:hover {
      margin: 0 16px;
      color: $cb-button-bg-blue;
      font-weight: bold;
    }
  }

  #breadcrumbs {
    color: #999;
    margin-bottom: 20px;
  }

  .skip-breadcrumbs {
    #breadcrumbs {
      display: none;
    }
  }

  .page-subheader {
    padding-bottom: 20px;
    font-weight: bold;
  }

  .page-delimeter {
    margin-bottom: 50px;
  }

  .customer-health-table {
    width: 100%;

    tr {
      th {
        text-align: center;
        vertical-align: middle;
      }

      td {
        text-align: right;

        &:first-child {
          text-align: left;
        }
      }
    }
  }

  .split-test-progress {
    position: relative;
    margin-bottom: 0;

    span {
      font-family: Consolas, monaco, monospace;
      font-size: 12px;
      position: absolute;
      top: 0;
      z-index: 2;
      color: black;
      text-align: center;
      width: 100%;
    }

    .bar {
      z-index: 1;
      position: absolute;
    }

    .small-text {
      display: block;
      text-align: center;

      &.winner-text {
        color: #999;
      }
    }
  }

  .integration_request_logs_table {
    th,
    td {
      &.details {
        width: 65%;
        word-break: break-word;
        position: relative;
      }
    }
  }

  /* Settings Changes Report */
  .settings_changes_table {
    th,
    td {
      &.time {
        width: 10%;
      }

      &.campaign {
        width: 25%;
      }

      &.action {
        width: 65%;
        word-break: break-word;
        position: relative;
      }
    }

    .action {
      .tooltip.left {
        .tooltip-inner,
        .tooltip-arrow {
          margin-left: -7px;
        }
      }

      .editor-changelog-button {
        top: 30px;
        bottom: auto;
        cursor: default;
        box-shadow: none;
      }
    }

    .diff-block {
      overflow: auto;
      max-width: 600px;
    }

    .description {
      margin: 0;

      .value {
        font-style: italic;

        .new,
        .created,
        .updated {
          background: rgb(221 255 221);
          color: rgb(0 136 0);
        }

        .old,
        .deleted {
          background: rgb(255 238 238);
          color: rgb(187 0 0);
        }
      }
    }
  }

  .preview-integration {
    &-left {
      display: inline-block;
      vertical-align: middle;
      width: 45%;

      form {
        margin-bottom: 0;
      }
    }

    &-center {
      display: inline-block;
      vertical-align: middle;
      width: 4%;
    }

    &-right {
      display: inline-block;
      vertical-align: middle;
      width: 50%;
    }
  }

  /* Rebates page */
  .referrals_table {
    .h6 {
      font-size: 10px;
    }

    .unredeem_reason {
      font-size: 11px;
      color: gray;

      [class^="icon-"],
      [class*=" icon-"] {
        margin-top: -1px;
      }
    }

    td {
      &.action {
        text-align: center;
        width: 75px;

        a.btn {
          margin: 2px 0;
          min-width: 48px;
        }
      }

      &.email {
        width: 220px;
      }

      &.reason {
        text-align: center;
        width: 25px;
      }

      &.fraud {
        width: 65px;
        padding-top: 35px;
        text-align: center;
      }

      &.voided {
        text-decoration: line-through;
      }

      &.campaign_name {
        width: 150px;
      }

      &.subtotal {
        width: 150px;
      }

      &.date {
        width: 65px;
      }
    }

    tr {
      &.odd {
        td {
          background-color: #f9f9f9;
        }
      }
    }
  }

  // referrals#index referrals#show
  .activities_rebates {
    .summary {
      text-align: center;
      font-weight: normal;
      font-size: 16px;

      .amount {
        font-weight: bold;
        margin-right: 20px;
      }
    }

    .rebate_list {
      margin-top: 25px;

      .table_title {
        font-size: 17px;
        float: left;
      }

      tr.voided {
        td {
          text-decoration: line-through;
        }

        td.rebate_status {
          text-decoration: none;
        }
      }
    }
  }

  table.ad_fr_compare {
    table-layout: fixed;
    width: 100%;
    font-size: 14px;
    line-height: 20px;

    tr {
      vertical-align: top;
    }

    .name {
      width: 110px;
      text-align: center;
      color: #aeadad;
      font-size: 12px;
    }

    .fr {
      text-align: right;
    }

    .ad {
      text-align: left;
    }
  }

  /* common for rebates page and rebates email */
  .rebates.list,
  .redeems.list {
    thead th {
      border-top: 1px solid #ccc;
      border-bottom: 1px solid #ddd;
      font-size: 14px;
      font-weight: bold;
      color: #555;
    }

    tfoot tr td {
      border-top: 1px solid #888;
      font-size: 14px;
      font-weight: bold;
    }
  }

  /* Purchases show page */
  .purchasesShow {
    table {
      .thumb {
        width: 75px;
      }
    }
  }

  .table_additional_information {
    font-size: 11px;
    background-color: #f0f0ff;
  }

  .hidden {
    display: none;
  }

  .campaigns .list {
    td {
      padding: 5px 0;
    }

    .campaign .icon {
      border-radius: 0;
      padding: 0 5px 0 0;
      width: 19px;
    }

    .name {
      width: 250px;
    }

    .type {
      width: 155px;
    }

    .count,
    .percent {
      padding-left: 0;
      padding-right: 0;
    }

    .percent {
      color: #777;
      font-size: 90%;
    }

    .count {
      text-align: right;
      padding-right: 2px;
      padding-left: 10px;
    }

    .actions {
      padding-left: 2px;
      width: 120px;
    }
  }

  /* campaigns#show */
  .campaign.show {
    border-bottom: 1px solid #ddd;
    margin-bottom: 30px;
    position: relative;
  }

  .deal_show {
    background-color: #fff;
    padding: 30px 30px 15px;
    box-shadow: 0 0 10px rgba(#000, 0.1);

    .details {
      font-size: 14px;
      margin: 0 420px 30px 0;
      word-wrap: break-word;

      h4 {
        font-size: 20px;
        line-height: 1.1;
        margin-bottom: 7px;
        vertical-align: bottom;
      }

      p {
        font-size: 14px;
        white-space: pre-line;
      }

      .badge {
        font-size: 13px;
        font-weight: normal;
      }
    }

    .details-title {
      margin-bottom: 10px;
      text-align: center;
    }

    .sample_offer {
      .deal_iframe {
        border: 1px solid #eee;
        border-radius: 5px;
        box-shadow: 0 2px 5px rgba(#000, 0.05);
        margin-bottom: 40px;

        iframe {
          display: block;
          margin: 0 auto;
        }
      }
    }

    // Funnel
    .funnel {
      border-left: 1px solid #ddd;
      bottom: -25px;
      margin-bottom: 25px;
      overflow: hidden;
      padding: 35px 0 25px 25px;
      position: absolute;
      right: 0;
      top: -30px;
      width: 359px;

      .sharing_channel {
        margin-bottom: 30px;
        text-align: center;
      }

      .description {
        width: 131px;
        margin-bottom: 5px;
      }
    }

    .funnel-container {
      font-size: 11px;
      height: 139px;
      left: 0;
      margin: 0 10px 15px;
      position: relative;
      text-align: center;

      &::after {
        content: "";
        background: url("~deal-funnel-6-fields.png") no-repeat;
        opacity: 0.6;
        position: absolute;
        right: 0;
        top: 0;
        width: 128px;
        height: 139px;

        @include retina {
          background-image: url("~deal-funnel-6-fields@2x.png");
          background-size: 128px 139px;
        }
      }

      &.is-large {
        &::after {
          background-image: url("~deal-funnel-7-fields.png");

          @include retina {
            background-image: url("~deal-funnel-7-fields@2x.png");
            background-size: 128px 139px;
          }
        }

        .field {
          div {
            height: 16px;
            line-height: 17px;
          }
        }
      }

      .field {
        border-radius: 10px;
        padding-left: 10px;
        transition: background 0.3s;

        @include clearfix;

        &:hover {
          background-color: rgba(#eb6733, 0.2);

          div {
            color: #eb6733;
            transition: color 0.3s ease;
          }
        }

        div {
          cursor: default;
          height: 18px;
          line-height: 19px;
          padding-bottom: 2px;
          transition: color 0.2s;

          &.left {
            border-bottom: 1px dotted #aaa;
            font-size: 11px;
            margin-right: 19px;
            text-align: left;
            vertical-align: middle;
            width: 180px;
            display: inline-block;
          }

          &.right {
            float: none;
            display: inline-block;
            text-align: center;
            vertical-align: middle;
            width: 130px;
          }
        }

        &:first-child {
          div {
            padding-top: 2px;
            padding-bottom: 4px;
          }
        }
      }
    }

    .stat_box {
      border-bottom: 1px solid #ddd;
      margin-bottom: 25px;

      td {
        padding-left: 0;

        &:first-child {
          width: 38%;
        }
      }
    }
  }

  /* All Public facing pages for deal page */
  .preview {
    h3 {
      padding-top: 0;
    }

    iframe {
      border: 1px solid #ccc;
    }
  }

  .purchases {
    .index {
      h1 {
        float: left;
      }

      .links {
        float: right;
      }
    }
  }

  /* Origins#index pages */
  .origins {
    .index {
      .csv_download {
        float: right;
      }
    }
  }

  .field.warning input.period {
    border-color: #ffd324;
    box-shadow: 0 0 5px #ffd324;
  }

  // Validation
  // ----------
  .error_explanation {
    border: 1px solid #eed3d7;
    font-size: 16px;
    text-shadow: 0 1px 0 rgba(#fff, 0.5);
    padding: 17px 35px 8px 14px;
    border-radius: 4px;
    background-color: #f2dede;
    color: #b94a48;
    margin-bottom: 14px;
  }

  // Offer Blasts
  .offer-blasts-table .datagrid-actions {
    min-width: 80px;
  }

  .csv-import {
    &-preview-table {
      tr th {
        vertical-align: top;
      }

      .detected-column {
        background-color: #ecfbd4;
      }
    }

    &-preview-table-wrapper {
      overflow: auto;
    }
  }

  /* Static Asset Index Page */
  .static-assets-list {
    font-size: 14px;

    .datagrid-order {
      float: left;
      margin-right: 5px;
    }

    td.datagrid-name {
      font-size: 12px;
    }

    td.datagrid-preview {
      background: url("~checkerboard.png") !important;
    }

    .datagrid-delete {
      text-align: center;
    }
  }
}

.text-danger {
  color: #c00;
}

.btn-group {
  & > .tooltip + .dropdown-toggle {
    padding-left: 8px;
    padding-right: 8px;
    margin-left: -1px;
  }
}

.tooltip {
  white-space: normal;
  word-wrap: break-word;
}

ul.unordered {
  list-style-type: none;
  padding-left: 0;
}

.dropdown-report {
  overflow: visible !important;

  .link-preset {
    word-wrap: break-word;
    white-space: normal;
    padding: 0 5px;
  }

  .remove {
    padding: 0;
  }
}

[data-balloon]::after {
  line-height: 1.2;
  font-family: $gotham-book !important;
}

@media all and (width >= 1600px) {
  $filter-form-width: 420px;

  body.application.is-wide-report {
    padding-left: $filter-form-width;

    .App-holder {
      padding-right: 20px;
      padding-left: 20px;
    }

    .navbar .container,
    .container {
      box-sizing: border-box;
      width: 1260px;
    }

    #breadcrumbs {
      width: 100%;
      padding-left: 0;
      padding-right: 0;
    }

    .filter-fieldset {
      width: 100%;
    }

    .filter-form {
      box-sizing: border-box;
      position: fixed;
      left: 0;
      top: 0;
      bottom: 0;
      width: $filter-form-width;
      overflow: auto;
      margin: 0;
      padding: 40px 25px 0 40px;
      z-index: 10;
      background: #f8f8f8;
      border-right: 1px solid #ccc;
      border-bottom: 1px solid #ccc;
      border-bottom-right-radius: 4px;
      border-bottom-left-radius: 4px;
      overflow-x: hidden;
      max-width: 420px;

      > * {
        position: relative;
      }

      @include clearfix;

      .field {
        @include clearfix;
      }

      .span9,
      .span11 {
        width: 100%;
      }

      .span4 {
        width: ($fluidGridColumnWidth * 6) + ($fluidGridGutterWidth * 5) !important;
      }

      .wide-select {
        width: 100% !important;
      }

      .span6 {
        &.is-wide-span3 {
          width: 220px;

          input {
            width: auto;
          }
        }
      }

      /*
        Choosen margins
       */
      .row .span3 .field {
        margin-bottom: 10px;
      }

      .panel-heading {
        a {
          color: #404040;
          cursor: default;

          &:hover {
            text-decoration: none;
          }
        }
      }

      hr {
        border-top: none;
        border-bottom: none;
      }

      h3 {
        font-size: 120%;
        font-weight: bold;
        margin: 10px 0 0;
      }

      .js-campaign-slug-select {
        &:focus,
        &:hover {
          width: 680px;
        }
      }

      .row-fluid {
        display: flex;
        flex-direction: column;

        .span4 {
          display: flex;
          flex-direction: column;
          min-width: 240px;
          margin: 0 0 4px;
          min-height: unset;
        }
      }
    }

    &.is-people-report {
      .span2 {
        display: block;
        float: none;
      }
    }

    &.is-by-channel {
      .span6 {
        float: none;
        display: block;
        width: 380px;
        padding-right: 20px;
      }
    }
  }
}

.buttons-holder {
  border-top: 1px solid #dad7d7;
  padding: 20px 30px;

  @include vertical-gradient(#f7f6f4, #ebe9e9);
  @include clearfix;
}

.only-print {
  display: none !important;

  @include print {
    display: block !important;
  }
}

.no-print {
  @include print {
    display: none !important;
  }
}

.filter-form {
  details {
    margin: 10px 0 20px;
    border-bottom: 1px solid #ccc;
  }

  summary {
    list-style: none;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    cursor: pointer;
    font-size: 120%;
    font-weight: bold;
    margin-bottom: 12px;
    width: 100%;

    .sign-svg {
      margin-left: 8px;
      transform: rotate(180deg);
    }

    &:hover {
      color: rgb(0 112 217);

      g {
        color: rgb(0 112 217);
      }

      .sign-svg .sign-svg-border {
        fill: rgb(0 112 217);
      }
    }
  }

  summary::-webkit-details-marker,
  summary::marker {
    display: none;
    content: "";
  }

  details[open] {
    padding-bottom: 20px;

    summary .sign-svg {
      transform: rotate(0);
    }
  }

  .form-footer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: sticky;
    bottom: 0;
    background: #f8f8f8;
    padding: 16px 16px 16px 40px;
    margin: 0 -25px 0 -40px;

    .footer-row {
      display: flex;
      align-items: center;

      *:not(:last-child) {
        margin: 0;
        margin-right: 10px;
      }

      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }
}

@media all and (width >= 1320px) {
  body.application {
    &.is-report,
    &.is-wide-report {
      .navbar .container,
      .container {
        width: 1260px;
        box-sizing: border-box;
      }

      #breadcrumbs {
        box-sizing: border-box;
        width: 1260px;
      }
    }
  }
}

@media all and (width <= 1600px) {
  .filter-form {
    summary {
      width: 66%;
    }

    details {
      border-bottom: none;
    }

    .form-footer {
      position: static;
      background: none;
      border-top: none;
      flex-direction: row;
      align-items: center;

      .footer-row {
        display: flex;
        align-items: center;

        *:not(:last-child) {
          margin: 0;
          margin-right: 10px;
        }

        &:not(:last-child) {
          margin: 0 10px 0 0;
        }
      }
    }

    .campaign-filter {
      width: 66%;
    }
  }
}

@media all and (width <= 1320px) {
  body.application {
    &.is-wide-report,
    &.is-report {
      .container {
        width: 100%;
        padding-left: 25px;
        padding-right: 25px;
        box-sizing: border-box;
      }

      #breadcrumbs {
        padding-left: 0;
        padding-right: 0;
      }

      .filter-form {
        summary {
          width: 66%;
        }
      }
    }
  }
}
